import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [status, setStatus] = useState("");
  const form = useRef();

  const onSubmit = (data, e) => {
    e.preventDefault();

    emailjs.sendForm('service_yklcryf', 'template_s4qjyzm', form.current, 'v8CWVZFHEAOzC3vHa')
      .then((result) => {
        console.log(result.text);
        setStatus("Message sent successfully!");
      }, (error) => {
        console.log(error.text);
        setStatus("Failed to send the message. Please try again later.");
      });
  };

  return (
    <>
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="text"
                name="user_name"
                className="form-control theme-light"
                placeholder="Full name"
                {...register("user_name", { required: true })}
              />
              {errors.user_name && errors.user_name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="email"
                name="user_email"
                className="form-control theme-light"
                placeholder="Email address"
                {...register(
                  "user_email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
              />
              {errors.user_email && (
                <span className="invalid-feedback">{errors.user_email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <textarea
                name="message"
                rows="4"
                className="form-control theme-light"
                placeholder="Type comment"
                {...register("message", { required: true })}
              ></textarea>
              {errors.message && (
                <span className="invalid-feedback">Comment is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}
          <div className="col-12">
            <p>{status}</p>
          </div>

          <div className="col-12">
            <div className="btn-bar">
              <button className="px-btn px-btn-white">Send Message</button>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
