import React, { useState, useEffect } from "react";
import { TypeAnimation } from 'react-type-animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

const conctInfo = {
  phone: "",
  email: "saurabhkarya1@gmail.com",
};

const sliderContent = {
  name: "Saurabh Arya",
  description: `Offering bespoke product solutions in the software development space, tailored to elevate your business.`,
  btnText: "Download CV",
};

const Slider = () => {
  const texts = [
    "Product Obsessed",
    "Software Engineer",
  ];

// Function to scroll to Contact section
const scrollToContact = () => {
  const contactSection = document.getElementById("contactus");
  contactSection.scrollIntoView({ behavior: "smooth" });
};

  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="hb-info">
            <a href="tel:+13477099958">{conctInfo.phone}</a>
            <a href="mailto:saurabhkarya1@gmail.com">
              {conctInfo.email}
            </a>
          </div>
          <div className="hb-lang">
            <ul className="nav"></ul>
          </div>
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  Hello, my name is
                </h6>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <TypeAnimation
                    sequence={[
                      ...texts.map((text, i) => [text, i < texts.length - 1 ? 1000 : 0]),
                    ].flat()}
                    speed={50}
                    repeat={Infinity}
                    className="loop-text lead"
                    style={{ display: 'inline-block' }}
                  />
                </div>

                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                >
                  {sliderContent.description}
                </p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a
                    className="px-btn px-btn-white"
                    href="img/Saurabh-CV.pdf"
                    download
                  >
                    {sliderContent.btnText}
                  </a>
                </div>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <button
                    className="px-btn px-btn-white"
                    onClick={scrollToContact}
                  >
                    Contact Me
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}
        <div
          className="hb-me"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "img/slider/saurabh-cropped.png"
              })`,
          }}
        ></div>
      </section>
      <FontAwesomeIcon
        className="bounce-arrow"
        icon={faArrowDown}
        onClick={scrollToContact}
      />

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
