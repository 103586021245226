import React from "react";
import Skills from "../skills/Skills";

const resumeContent = [
  {
    jobPosition: `Technical Product Manager`,
    jobType: `Argon Studios | Hybrid`,
    jobDuration: `Jan 2023 - Present`,
    timeDuraton: `Part Time`,
    compnayName: "Argon Studios",
    jobDescription: `At Argon Studios, I play a pivotal role in business development and engineering, successfully converting incoming leads 
    into dedicated freelance clients and building their projects.`,
    delayAnimation: "",
  },
  {
    jobPosition: `Software Engineer (Product)`,
    jobType: `Cache Invest | Onsite`,
    jobDuration: `May 2022 - April 2023`,
    timeDuraton: `Full Time`,
    compnayName: "Cache Invest",
    jobDescription: `As a Full-Stack Software Engineer at Cache, a fintech start-up, I have leveraged my comprehensive 
    skills in Java, Python, AWS and agile product development to drive 80% of production deployments for new features and 
    bug fixes in my initial six months. Simultaneously, I have reduced technical debt, developed CI/CD pipelines, 
    crafted API documentation, and strategized branching for production deployment - ultimately streamlining our tech 
    development lifecycle and pioneering unique market features.`,
    delayAnimation: "100",
  },
  // {
  //   jobPosition: `Animation Artist`,
  //   jobType: `IbThemes | Remote`,
  //   jobDuration: `Jan 2019 - Present`,
  //   timeDuraton: `Full Time`,
  //   compnayName: "IB Themes",
  //   jobDescription: ` Consectetur adipisicing elit,lorem ipsum dolor sit amet,
  //   sed do eiusmod tempor incididunt ut labore et dolore magna
  //   aliqua. Ut enim ad minim veniam, quis nostrud exercitation
  //   ullamco laboris nisi ut aliquip ex ea commodo consequat.
  //   Duis aute irure dolor.`,
  //   delayAnimation: "200",
  // },
];

const educatonContent = [
  {
    passingYear: "2021",
    degreeTitle: "Certified AWS Solutions Architect",
    instituteName: "Amazon Web Services",
  },
  {
    passingYear: "2018",
    degreeTitle: "Masters in Finance (Applied)",
    instituteName: "RMIT",
  },
  {
    passingYear: "2016",
    degreeTitle: "Bachelors of Finance (Capital Markets & Statistics) ",
    instituteName: "The Australian National University",
  },
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div
                className="resume-row"
                key={i}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay={val.delayAnimation}
              >
                <div className="row">
                  <div className="col-md-4 col-xl-3">
                    <div className="rb-left">
                      <h6>{val.jobPosition}</h6>
                      <label>{val.jobType}</label>
                      <p>{val.jobDuration}</p>
                      <div className="rb-time">{val.timeDuraton}</div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p>{val.jobDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Education & Skills</h3>{" "}
          </div>

          <div className="row align-items-center">
            <div
              className="col-lg-4 m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <ul className="aducation-box">
                {educatonContent.map((val, i) => (
                  <li key={i}>
                    <span>{val.passingYear}</span>
                    <h6>{val.degreeTitle} </h6>
                    <p>{val.instituteName}</p>{" "}
                  </li>
                ))}
              </ul>
            </div>
            {/* End .col */}

            <div
              className="col-lg-7 ml-auto m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <Skills />
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
