import React from 'react';
import HomeLightAnimation from '../views/all-home-version/HomeLightAnimation';
import NotFound from '../views/NotFound';
import {Routes, Route} from 'react-router-dom';
import ScrollTopBehaviour from '../components/ScrollTopBehaviour';

const AllRoutes = () => {
  return (
    <>
      <ScrollTopBehaviour />
      <Routes>
        <Route exact path="/" element={<HomeLightAnimation />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
