import React, {useEffect} from 'react';
import AllRoutes from './router/AllRoutes';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.css'; // Import your custom CSS file

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="app">
      {' '}
      {/* Add a class to the root element */}
      <ScrollToTop />
      <AllRoutes />
    </div>
  );
};

export default App;
