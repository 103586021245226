import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>What are you building? Get in touch</h4>
        <p>
          I have a limited capacity for clients, but if I am unable to take on
          your project, I can tap into my network to find the right person or
          solution for you. Please feel free to reach out to me.
        </p>

        <ul>
          <li className="media">
            <i className="icon icon-envelope"></i>
            <span className="media-body">saurabhkarya1@gmail.com</span>
          </li>
          {/* End li */}
          <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body">(United States) +1 347 709 9958</span>
          </li>
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
